<template>
    <div>
        <div class="row section-header">
            <div class="col-md-3 section-title">
                <h3>Guide</h3>
            </div>

            <div class="col-md-6">

            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>
        <br>


        <div class="content">

            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <!-- RIASSUNTO -->

                <div class="card">
                    <b-row>
                        <b-col sm="3">
                            <h5>Ore totali pratica</h5>
                            <h2>{{ summary.practiceTotalSeconds | hours }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Guide complessive</h5>
                            <h2>{{ summary.practiceTotalAmount }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Ore totali simulatore</h5>
                            <h2>{{  summary.simulatorTotalSeconds | hours }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Simulazioni complessive</h5>
                            <h2>{{ summary.simulatorTotalAmount }}</h2>
                        </b-col>
                    </b-row>
                </div>
                <br/>

                <div class="container-fluid">
                    <h5><b-icon icon="search"/> Filtra le guide  <b-button v-b-toggle.collapse-1 variant="link">Mostra filtri</b-button></h5>
                    <b-collapse id="collapse-1" class="mt-2">
                        <div class="row">
                            <div class="col-sm-4">
                                <b-form-group id="name" label="Nome studente" label-for="name" description="">
                                    <b-input v-model="filter.name"></b-input>
                                </b-form-group>
                            </div>
                            <div class="col-sm-2">
                                <b-form-group id="drivingLicenseId" label="Patente" label-for="drivingLicenseId" description="">
                                    <b-form-select v-model="filter.drivingLicenseId">
                                        <option value="">TUTTE</option>
                                        <option v-for="license in drivingLicenses" v-bind:value="license.id" :key="license.id">
                                            {{ license.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-sm-2" v-if="hasRole('owner')">
                                <b-form-group id="instructorId" label="Istruttore" label-for="instructorId" description="">
                                    <b-form-select v-model="filter.instructorId">
                                        <option value="">TUTTI</option>
                                        <option v-for="instructor in instructors" v-bind:value="instructor.id" :key="instructor.id">
                                            {{ instructor.firstname }} {{ instructor.lastname }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-sm-4">
                                <label>Periodo</label><br>
                                <date-picker v-model="filter.range" range :lang="lang" value-type="format"></date-picker>
                            </div>
                        </div>
                        <b-button variant="outline-secondary" @click="search"> <b-icon icon="search"/> Cerca</b-button>
                        <b-button variant="link" @click="resetFilter"> <b-icon icon="x"/> reset</b-button>
                        <hr>
                    </b-collapse>
                </div>

                <br>

                <div class="top-content row">
                    <div class="col-sm-8">
                        <button class="btn btn-primary btn-add" @click="currentId = 0; currentPractice = null; creatorEditorType = 'interactive'" style="margin-right: 7px">
                            <b-icon icon="play-fill"/> Avvia una Guida
                        </button>
                        <button class="btn btn-outline-primary btn-add" @click="currentId = 0; currentPractice = null; creatorEditorType = 'static'">
                            <b-icon icon="plus"/> Aggiungi Guida
                        </button>
                    </div>
                    <div class="col-sm-4 text-right">
                        <b-button variant="link" @click="downloadCsv" v-if="hasRole('admin') || hasRole('owner')" :disabled="numberOfCsv == 0"> <b-icon icon="download"/> Scarica i file CSV con le metriche ({{ numberOfCsv }})</b-button>
                    </div>
                </div>
                <div class="card">
                    <div class="text-right">
                        <button class="btn btn-outline-secondary btn-sm" @click="refreshList">
                            <b-icon icon="arrow-repeat"/> Aggiorna
                        </button>
                    </div>
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center ">Data</th>
                            <th scope="col" class="text-center opt">Durata</th>
                            <th scope="col" class="text-center opt">Istruttore</th>
                            <th scope="col" class="text-center opt">Programma</th>
                            <th scope="col" class="text-center opt">Mezzo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': practice.id == currentId, 'text-muted': practice.student.deleted, 'text-danger': (practice.vehicleName === '- mezzo non trovato -') }"
                            v-for="practice in practices"
                            :key="practice.id"
                            @click="setActivePractice(practice)"
                        >
                            <td>
                                {{ practice.student.firstname }} {{ practice.student.lastname }}
                                <span v-if="practice.student.deleted">eliminato</span>
                            </td>
                            <td class="text-center ">{{ formatDate(practice.dateStart) }}</td>
                            <td class="text-center opt">{{ getDuration(practice) }}</td>
                            <td class="text-center opt">{{ getFullname(practice.instructor) }}</td>
                            <td class="text-center opt">{{ practice.program.name }}</td>
                            <td class="text-center opt">{{ practice.vehicleName }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.practices.length == 0">Ancora nessuna guida è stata svolta</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <practice-view :practice-id="currentPractice.id" :key="currentPractice.id"
                                  v-on:deleted-practice="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <practice-create
                        v-if="creatorEditorType == 'static'"
                        v-on:created-practice="refreshList()"
                        v-on:closed-editor="closeEditor()"/>

                    <practice-create-live
                        v-if="creatorEditorType == 'interactive'"
                        v-on:created-practice="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import PracticeDataService from "@/components/practice/PracticeDataService";
import PracticeCreateLive from "@/components/practice/PracticeCreateLive";
import PracticeCreate from "@/components/practice/PracticeCreate";
import PracticeView from "@/components/practice/PracticeView";
import moment from 'moment';
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";
import InstructorDataService from "@/components/instructor/InstructorDataService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';

export default {
    name: "practice-index",
    components: {PracticeView, PracticeCreateLive, PracticeCreate, DatePicker},
    data() {
        return {
            practices: [],
            currentPractice: null,
            currentId: -1,
            creatorEditorType: 'static', // static (PracticeCreate), interactive (PracticeCreateAlt)
            summary: {},
            drivingLicenses: [],
            instructors: [],
            numberOfCsv: 0,
            filter: {
                name: '',
                drivingLicenseId: '',
                instructorId: '',
                range: null
            },
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            },
        };
    },
    methods: {
        retrievePractices() {
            PracticeDataService.getAll()
                .then(response => {
                    this.practices = response.data;
                    this.updateSummary();
                    // hide practice with deleted profiles
                    this.practices = this.practices.filter(p => !p.student.deleted);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrievePractices();
            this.currentPractice = null;
            this.currentId = -1;
        },

        refreshCurrentPractice(practice) {
            this.practices.forEach(function (item) {
                if (item.id == practice.id) {
                    //item.name = practice.name;
                    // da fare
                }
            });
            this.closeEditor();
            //this.currentPractice = practice;
            //this.currentId = practice.id;
        },

        setActivePractice(practice) {
            this.currentPractice = practice;
            this.currentId = practice.id;
        },

        removeAllPractices() {
            PracticeDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        search() {
            this.currentPractice = null;
            this.currentId = -1;
            if (this.filter.range)
            {
                if (this.filter.range[0] === null) this.filter.range = null;
                else {
                    this.filter.range[0] = this.filter.range[0].substr(0, 10) + ' 00:00:00';
                    this.filter.range[1] = this.filter.range[1].substr(0, 10) + ' 23:59:59';
                }
            }
            PracticeDataService.findByFilter(this.filter)
                .then(response => {
                    this.practices = response.data;
                    this.updateSummary();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        resetFilter() {
            this.filter.name = '';
            this.filter.drivingLicenseId = '';
            this.filter.instructorId = '';
            this.filter.range = null;
            this.retrievePractices();
        },
        closeEditor() {
            this.currentPractice = null;
            this.currentId = -1;
        },

        formatDate(datetime) {
            return moment(datetime).format('DD/MM/YYYY HH:mm');
        },

        getDuration(practice) {
            const start = moment(practice.dateStart);
            const end = moment(practice.dateEnd);
            const duration = end.diff(start)
            return moment(duration).utc().format('HH:mm:ss');
        },

        getFullname(profile) {
            if (profile) return profile.firstname;
            else return '-';
        },

        updateSummary() {
            this.summary = {
                practiceTotalSeconds: 0,
                practiceTotalAmount: 0,
                simulatorTotalSeconds: 0,
                simulatorTotalAmount: 0,
            }

            let practices = this.practices.filter(p => p.isVirtual == false);
            let simulator = this.practices.filter(p => p.isVirtual == true);
            for (let item of practices) {
                const duration = moment(item.dateEnd).diff(moment(item.dateStart), 'seconds');
                this.summary.practiceTotalSeconds += duration;
                this.summary.practiceTotalAmount += 1;
            }
            for (let item of simulator) {
                const duration = moment(item.dateEnd).diff(moment(item.dateStart), 'seconds');
                this.summary.simulatorTotalSeconds += duration;
                this.summary.simulatorTotalAmount += 1;
            }

            this.numberOfCsv = this.practices.filter(p => p.csvFile !== null).length;
        },

        downloadCsv() {
            PracticeDataService.download(this.filter)
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], {
                        type: "octet/stream",
                    }));
                    let fURL = document.createElement('a');

                    fURL.href = fileURL;
                    let filename = 'csv-guide.zip';
                    fURL.setAttribute('download', filename);
                    document.body.appendChild(fURL);

                    fURL.click();
                })
                .catch(e => {
                    console.log(e);
                });
        }
    },
    filters: {
        hours: function (seconds) {
            return moment.utc(seconds*1000).format('HH:mm:ss');
        },
    },
    mounted() {
        this.retrievePractices();
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });

        InstructorDataService.getAll()
            .then(response => {
                this.instructors = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>


<style>
</style>
