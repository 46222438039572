<template>
    <div class="topic-tree" v-if="topic.status || edit || showAllTopics">
        <div class="label-wrapper  row">
            <div :style="indent" :class="labelClasses" class="col-8">
                <span class="label-caret" @click="toggleChildren">
                    <b-icon-caret-down-fill v-if="topics && showChildren"/>
                    <b-icon-caret-right-fill v-if="topics && !showChildren"/>
                </span>
                <span class="label-index">{{ composeOrder(topic.order) }}</span>
                <span class="label-title" :class="titleClass" v-b-toggle="'collapse-'+topic.id"
                      style="outline: none">{{ label }} </span>
            </div>
            <div class="col-3">
                <span class="text-muted small text-truncate" v-b-toggle="'collapse-'+topic.id"
                      style="outline: none; margin-left: 20px">
                    <span class="when-opened">
                        <b-icon-eye-slash/> <span class="d-none d-sm-inline"> dettagli</span>
                     </span>
                     <span class="when-closed">
                         <b-icon-eye/> <span class="d-none d-sm-inline"> dettagli</span>
                     </span>
                    </span>
            </div>
            <div class="col-1">
                <span :class="topic.status">
                    <b-icon-check-square v-if="topic.status == 'Completed'"/>
                    <b-icon-exclamation-square
                        v-if="topic.status == 'CompletedWithErrors' || topic.status == 'NotCompletedWithErrors'"/>
                    <b-icon-dash-square v-if="!topic.status"/>
                </span>

            </div>

            <b-collapse :id="'collapse-'+topic.id" :visible="print" class="col-12">
                <br/>
                <div class="activity-history"
                     v-for="activity in topicsActivityLog"
                     :key="activity.id">
                        <span :class="activity.status">
                                <b-icon-check-circle v-if="activity.status == 'Completed'"/>
                                <b-icon-exclamation-circle-fill
                                    v-if="activity.status == 'CompletedWithErrors' || topic.status == 'NotCompletedWithErrors'"/>
                                <b-icon-dash-circle v-if="!activity.status"/>
                                {{ radioOptions.find(item => item.value == activity.status).text }}</span>
                    <br>
                    <span style="opacity: .7"><b-icon icon="calendar3"/> {{ activity.registeredAt | formatDateTime }} ({{ getDuration(activity.practice) |formatDuration }})</span>

                    <br v-if="activity.teacherNote"/>
                    <span v-if="activity.teacherNote"><b-icon icon="clipboard"/> {{ activity.teacherNote }}</span>
                    <br>
                    <span v-if="activity.practice"><img src="../../assets/vehicle.png"> {{ activity.practice.vehicleName }}</span>
                </div>

                <div v-if="edit && topic.newActivityLog" class="new-activity-log">
                    <h5 v-if="edit">Aggiungi una valutazione</h5>

                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            :id="'topic-'+topic.id"
                            v-model="topic.newActivityLog.status"
                            :aria-describedby="ariaDescribedby"
                            :name="'topic-'+topic.id"
                            buttons
                            button-variant="practice-status-radio"
                        >
                            <b-form-radio value="Completed" class="text-success">
                                <b-icon-check-circle/>
                                Completato
                            </b-form-radio>
                            <b-form-radio value="CompletedWithErrors" class="text-danger">
                                <b-icon-exclamation-circle-fill/>
                                Completato con errori
                            </b-form-radio>

                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        label=""
                        label-for="teacherNote"
                        description=""
                        class="mb-0"
                    >
                        <b-textarea v-model="topic.newActivityLog.teacherNote"
                                    placeholder="Aggiungi una nota"></b-textarea>
                    </b-form-group>
                </div>

                <div v-else>
                    <div class="activity-history" v-if="topicsActivityLog.length == 0">Questa attività è ancora da
                        svolgere
                    </div>
                </div>

            </b-collapse>

        </div>



        <div v-if="showChildren">
            <practice-topic-tree-view
                v-for="topic in topics"
                :topic="topic"
                :key="topic.id"
                :topics="topic.children"
                :label="topic.title"
                :depth="depth + 1"
                :parentOrder="topic.order"
                :activityLogs="activityLogs"
                :edit="edit"
                :showAllTopics="showAllTopics"
            />
        </div>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "practice-topic-tree-view",
    props: ['topics', 'label', 'topic', 'depth', 'parentOrder', 'activityLogs', 'edit', 'showAllTopics','print'],
    data() {
        return {
            topicsActivityLog: [],
            radioOptions: [
                {text: 'Completato', value: 'Completed'},
                {text: 'Completato con errori', value: 'CompletedWithErrors'},
                {text: 'Non completato con errori', value: 'NotCompletedWithErrors'},
                {text: 'Da completare', value: 'NotCompleted'},
            ],
            showChildren: true,
            x: null
        }
    },
    computed: {
        iconClasses() {
            return {
                'chevron-right': !this.showChildren,
                'chevron-down': this.showChildren
            }
        },
        labelClasses() {
            return {'has-children': this.topics}
        },
        titleClass() {
            let status = this.topic.status
            return {
                'text-muted': !status,
                'text-success': (status == 'Completed'),
                'text-warning': (status == 'NotCompleted'),
                'text-danger': (status == 'NotCompletedWithErrors' || status == 'CompletedWithErrors'),
            }
        },
        indent() {
            return {transform: `translate(${this.depth * 25}px)`}
        }
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        setActiveTopic(topic) {
            this.$parent.setActiveTopic(topic);
        },
        composeOrder(topicOrder) {
            let order = '';
            if (this.parentOrder) {
                order += this.parentOrder + '.';
            }
            order += topicOrder
            return order
        },
        resetRadio() {
            this.topic.newActivityLog.status = null;
            this.topic.newActivityLog.teacherNote = null;
        },
        setTopicStatus() {
            const topicDoneArray = this.activityLogs.filter(element => element.topicId == this.topic.id) || null;
            if (topicDoneArray) {
                this.topicsActivityLog = topicDoneArray;
                //enum('NotCompleted', 'NotCompletedWithErrors', 'CompletedWithErrors', 'Completed')
                let status = {
                    Completed: 0,
                    CompletedWithErrors: 0,
                    NotCompletedWithErrors: 0,
                    NotCompleted: 0,
                };
                // check ultimate status inside all activities
                for (const activity of topicDoneArray) {
                    if (activity.status == 'Completed') {
                        status.Completed++;
                    } else if (activity.status == 'CompletedWithErrors') {
                        status.CompletedWithErrors++;
                    } else if (activity.status == 'NotCompletedWithErrors') {
                        status.NotCompletedWithErrors++;
                    } else if (activity.status == 'NotCompleted') {
                        status.NotCompleted++;
                    }
                }
                if (status.Completed > 0) this.topic.status = 'Completed';
                else if (status.CompletedWithErrors > 0) this.topic.status = 'CompletedWithErrors';
                else if (status.NotCompletedWithErrors > 0) this.topic.status = 'NotCompletedWithErrors';
                else if (status.NotCompleted > 0) this.topic.status = 'NotCompleted';
                else this.topic.status = null;
            }
        },
        getDuration(practice) {
            const start = moment(practice.dateStart);
            const end = moment(practice.dateEnd);
            const diff = end.diff(start);
            return diff;
        }
    },
    filters: {
        formatDateTime: function (date) {
            return moment(date).format('DD/MM/YYYY - HH:mm');
        },
        formatDuration(diff) {
            const duration = moment.duration(diff);
            const hours = (duration.hours() > 0) ? duration.hours(): '00';
            const minutes = (duration.minutes() > 0) ? duration.minutes(): '00';
            const seconds = (duration.seconds() > 0) ? duration.seconds(): '00';
            let result = hours + ':' + minutes + ':' + seconds + 's';
            return result;
        }
    },
    mounted() {
        this.topic.newActivityLog = {
            status: null,
            teacherNote: null
        };
        this.setTopicStatus();
    }
}
</script>

<style scoped>
.topic-tree .label-wrapper {

    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
}

.topic-tree .has-children {
    cursor: pointer;
}

.topic-tree .label-index {
    font-weight: bold;
    padding-right: 10px;
}

.topic-tree .label-caret {
    display: inline-block;
    width: 25px;
}

.Completed {
    color: #28a745;
}

.NotCompletedWithErrors, .CompletedWithErrors {
    color: #dc3545
}

.NotCompleted {
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.activity-history {
    margin-left: 30px;
    border-top: 1px solid #eee;
    padding: 10px 0px;
    font-size: .90rem;
    border-left: 3px solid black;
    padding-left: 10px
}

.new-activity-log {
    border-top: 1px solid #eee;
    margin-top: 15px;
    margin-left: 30px;
    padding: 10px;
    font-size: .90rem;
    border-left: 3px solid #eee;
}

.btn-practice-status-radio {
    font-size: .90rem;
    opacity: .7;
    filter: grayscale(70%);
}

.btn-practice-status-radio:hover {
    opacity: 1;
    filter: grayscale(0%);
}

.btn-practice-status-radio:active {
    transform: scale(.97);
    border: 1px solid #ddd;
}

.btn-practice-status-radio.active {
    opacity: 1;
    filter: grayscale(0%);
    border: 1px solid #ddd;
}

@media only screen and (max-width: 600px) {
    .btn-practice-status-radio, .btn-group {
        display: block;
    }
}
</style>



