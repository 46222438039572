import http from "../../helpers/http-common";

class PracticeDataService {
    getAll() {
        return http.get("/practices");
    }

    get(id) {
        return http.get(`/practices/${id}`);
    }

    create(data) {
        return http.post("/practices", data);
    }

    update(id, data) {
        return http.put(`/practices/${id}`, data);
    }

    delete(id) {
        return http.delete(`/practices/${id}`);
    }

    deleteAll() {
        return http.delete('/practices');
    }

    findByFilter(filter) {
        let query = '';
        for (const property in filter) {
            if (filter[property]) {
                if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                else query += `${property}=${filter[property]}&`
            }
        }
        return http.get(`/practices?${query.slice(0, -1)}`);
    }

    download(filter) {
        let query = '';
        for (const property in filter) {
            if (filter[property]) {
                if (property == 'range') query += `start=${filter[property][0]}&end=${filter[property][1]}&`
                else query += `${property}=${filter[property]}&`
            }
        }
        return http.get(`/practices/download_csv?${query.slice(0, -1)}`, {responseType: "blob"});
    }
}

export default new PracticeDataService();
