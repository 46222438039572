<template>
    <div>
        <div v-if="currentPractice" class="card">
            <h1>Dettagli guida</h1>
            <p>Studente:
                <b v-if="currentPractice.student">{{ currentPractice.student.firstname }} {{ currentPractice.student.lastname }}</b>
                <i v-else class="text-muted">Studente eliminato</i><br>
                <span v-if="currentPractice.instructor">
                Istruttore <b>{{ currentPractice.instructor.firstname }} {{ currentPractice.instructor.lastname }}</b><br>
                    </span>
                Data: <b>{{ formatDate(currentPractice.dateStart) }}</b><br>
                Durata: <b>{{ getDuration(currentPractice) }}</b><br>
                <span v-if="currentPractice.vehicleName">Mezzo: <b>{{ currentPractice.vehicleName }}</b> <span v-if="currentPractice.licensePlate">({{ currentPractice.licensePlate }})</span><br></span>
            </p>

            <div v-if="currentPractice.activityLogs && currentProgram && currentProgram.topics">
                <h4>Programma {{ currentProgram.name }}</h4>
                <hr>
                <practice-topic-tree-view
                    v-for="topic in currentProgram.topics"
                    :topic="topic"
                    :key="topic.id"
                    :topics="topic.children"
                    :label="topic.title"
                    :depth="0"
                    :activityLogs="currentPractice.activityLogs"
                    :edit="false"
                />
            </div>
            <div v-if="currentPractice.activityLogs.length == 0">
                Non è stata svolta nessuna attività durante questa guida.
            </div>

        </div>
        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Practice...' }}</p>
        </div>
    </div>
</template>

<script>
import PracticeDataService from "@/components/practice/PracticeDataService";
import PracticeTopicTreeView from "@/components/practice/PracticeTopicTreeView";
import TopicDataService from "@/components/topic/TopicDataService";
import ProgramDataService from "@/components/program/ProgramDataService";
import moment from 'moment';

export default {
    name: "practice-edit",
    components: {PracticeTopicTreeView},
    data() {
        return {
            currentPractice: null,
            currentProgram: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["practiceId"],
    methods: {
        getPractice(id) {
            PracticeDataService.get(id)
                .then(response => {
                    this.currentPractice = response.data;
                    console.log(this.currentPractice);
                    this.currentPractice.active = (this.currentPractice.active === 1);

                    // recupero il programma scelto
                    ProgramDataService.get(this.currentPractice.programId)
                        .then(response => {
                            response.data.topics = null;
                            this.currentProgram = response.data;
                            TopicDataService.getAll(this.currentPractice.programId)
                                .then(response => {
                                    this.currentProgram.topics = response.data.rows;
                                    this.currentProgram.count = response.data.count;
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        archivePractice() {
            if(confirm(`Vuoi archiviare il practicema ${this.currentPractice.name}?`)) {
                PracticeDataService.archive(this.currentPractice.id)
                    .then(response => {
                        this.$emit('deleted-practice');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        deletePractice() {
            if(confirm(`Vuoi cancellare il practicema ${this.currentPractice.name}?`)) {
                PracticeDataService.delete(this.currentPractice.id)
                    .then(response => {
                        this.$emit('deleted-practice');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        formatDate(datetime) {
            return moment(datetime).format('DD/MM/YYYY HH:mm');
        },
        getDuration(practice) {
            const start = moment(practice.dateStart);
            const end = moment(practice.dateEnd);
            const duration = end.diff(start)
            return moment(duration).utc().format('HH:mm:ss');
        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getPractice(this.practiceId);

    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
