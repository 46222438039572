<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="savePractice">

                <b-form-group id="instructorId" label="Istruttore" label-for="instructorId" description="" v-if="!hasRole('instructor')">
                    <b-form-select v-model="practice.instructorId" :state="validateState('instructorId')" :disabled="(practice.dateStart !== null)">
                        <option value="0">- Scegli un istruttore -</option>
                        <option v-for="instructor in instructors" v-bind:value="instructor.id" :key="instructor.id">
                            {{ instructor.firstname }} {{ instructor.lastname }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="studentId" label="Studente" label-for="studentId" description="" v-if="practice.instructorId">
                    <b-form-select v-model="practice.studentId" :state="validateState('studentId')" :disabled="(practice.dateStart !== null)">
                        <option value="0">- Scegli uno studente -</option>
                        <option v-for="student in students" v-bind:value="student.id" :key="student.id">
                            {{ student.firstname }} {{ student.lastname }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="programId" label="Programma" label-for="programId" description="" v-if="practice.studentId">
                    <b-form-select v-model="practice.programId" :state="validateState('programId')" :disabled="(practice.dateStart !== null)">
                        <option value="0">- Scegli un programma -</option>
                        <option v-for="program in programs" v-bind:value="program.id" :key="program.id">
                            {{ program.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="vehicleId" label="Mezzo" label-for="vehicleId" description="" v-if="practice.programId">
                    <b-form-select v-model="practice.vehicleId" :state="validateState('vehicleId')" :disabled="(practice.dateStart !== null)">
                        <option value="0">- Scegli unmezzo -</option>
                        <option v-for="vehicle in vehicles" v-bind:value="vehicle.id" :key="vehicle.id">
                            {{ vehicle.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

            </b-form>

            <div v-if="practice.dateStart && currentProgram.topics">
                <br>
                <img src="../../assets/pulsating-dot.gif" width="25"/> Guida in corso
            </div>

            <div v-if="activityLogs && currentProgram && currentProgram.topics && practice.vehicleId && practice.dateStart">
                <br>
                <h5>Programma </h5>
                <hr>
                <practice-topic-tree-view
                    v-for="topic in currentProgram.topics"
                    :topic="topic"
                    :key="topic.id"
                    :topics="topic.children"
                    :label="topic.title"
                    :depth="0"
                    :activityLogs="activityLogs"
                    :edit="true"
                    :showAllTopics="true"
                />
            </div>



            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="startPractice" v-if="!practice.dateStart && practice.vehicleId"><b-icon-play-fill /> Inizia Guida</button>

                    <button class="btn btn-success " @click="savePractice" :disabled="this.$store.state.loading" v-if="practice.dateStart && currentProgram.topics"><b-icon-stop-fill /> Fine guida - Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>
    </div>

</template>

<script>
import PracticeDataService from "@/components/practice/PracticeDataService";
import {required} from 'vuelidate/lib/validators'
import InstructorDataService from "@/components/instructor/InstructorDataService";
import ProgramDataService from "@/components/program/ProgramDataService";
import StudentDataService from "@/components/student/StudentDataService";
import PracticeTopicTreeView from "@/components/practice/PracticeTopicTreeView";
import TopicDataService from "@/components/topic/TopicDataService";
import ActivityLogDataService from "@/components/activity_log/ActivityLogDataService";
import VehicleDataService from "@/components/vehicle/VehicleDataService";
import moment from 'moment';


export default {
    name: "practice-create-live",
    components: {PracticeTopicTreeView},
    data() {
        return {
            practice: {
                id: null,
                dateStart: null,
                dateEnd: null,
                instructorId: null,
                studentId: null,
                programId: null,
                vehicleName: null,
                vehicleId: null,
                licensePlate: null,
                topics: null
            },
            programs: [],
            vehicles: [],
            students: [],
            currentProgram: null,
            activityLogs: null,
            instructors: [],
            submitted: false,
            message: '',
            errorMsg: '',
    };
    },
    validations: {

        practice: {
            instructorId: {
                required,
            },
            studentId: {
                required,
            },
            programId: {
                required,
            },
            vehicleId: {
                required
            },
            instructorAvailabilityId: {}
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.practice[name];
            return $dirty ? !$error : null;
        },
        savePractice() {
            console.log(this.practice);

            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                this.practice.dateEnd = moment().format('YYYY-MM-DD HH:mm:ss');
                //aggiungi la data della guida
                //let doneAt = moment(this.practice.dateStart).format('YYYY-MM-DD');
                let doneAt = this.practice.dateStart;
                if (this.currentProgram.topics) {
                    this.setDoneAtRecursive(this.currentProgram.topics, doneAt);
                }

                this.practice.topics = this.currentProgram.topics;
                //console.log(this.practice.topics);
                if (this.practice.vehicleId) {
                    var vehicle = this.vehicles.find(obj => {
                        return obj.id == this.practice.vehicleId
                    })
                    if (vehicle.licensePlate) this.practice.licensePlate = vehicle.licensePlate;
                    if (vehicle.name) this.practice.vehicleName = vehicle.name;
                }

                PracticeDataService.create(this.practice)
                    .then(response => {
                        this.practice.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-practice');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        startPractice() {
            this.practice.dateStart = moment().format('YYYY-MM-DD HH:mm:ss');
        },
        setDoneAtRecursive(topics, doneAt) {
            for (let topic of this.currentProgram.topics) {
                if(topic.status && !topic.doneAt) {
                    topic.doneAt = doneAt
                }
                if (!topic.status) {
                    topic.doneAt = null
                }
                //console.log(topic.title, topic.doneAt)
                if (topic.children) {
                    this.setDoneAtRecursive(topic.children)
                }

            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
    },
    mounted() {
        // se l'utente è un istruttore lo segno come default

        if (this.hasRole('instructor')) {
            this.practice.instructorId = this.$store.state.user.profileId
        } else {
            InstructorDataService.getAll()
                .then(response => {
                    this.instructors = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        }

        ProgramDataService.getAll('practice')
            .then(response => {
                this.programs = response.data.filter(obj => obj.idSim === null);
            })
            .catch(e => {
                console.log(e);
            });

        VehicleDataService.getAll()
            .then(response => {
                this.vehicles = response.data.filter(obj => obj.isVirtual == false);
            })
            .catch(e => {
                console.log(e);
            });

        StudentDataService.getAll()
            .then(response => {
                this.students = response.data;
            })
            .catch(e => {
                console.log(e);
            });

    },
    watch: {
        practice: {
            handler() {
                if (this.practice.studentId && this.practice.programId) {

                    // recupero tutte le attività svolta dallo studente di quel programma
                    ActivityLogDataService.getByProgramAndStudent(this.practice.studentId, this.practice.programId)
                        .then(response => {
                            this.activityLogs = response.data;
                            // recupero il programma scelto
                            ProgramDataService.get(this.practice.programId)
                                .then(response => {
                                    response.data.topics = null;
                                    this.currentProgram = response.data;
                                    TopicDataService.getAll(this.practice.programId)
                                        .then(response => {
                                            this.currentProgram.topics = response.data.rows;
                                            this.currentProgram.count = response.data.count;
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            },
            deep: true,
            immediate: true,
        },
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
