import http from "../../helpers/http-common";

class ActivityLogDataService {
    getAll() {
        return http.get("/activity_logs");
    }

    getAllByStudent(profileId) {
        return http.get(`/activity_logs/${profileId}`);
    }

    getByProgramAndStudent(profileId, programId) {
        return http.get(`/activity_logs/${profileId}/${programId}`);
    }

    getAllByStudentAndDrivingLicense(profileId, drivingLicenseId) {
        return http.get(`/activity_logs/${profileId}/0/${drivingLicenseId}`);
    }

    findByName(name) {
        return http.get(`/activity_logs?name=${name}`);
    }
}

export default new ActivityLogDataService();
